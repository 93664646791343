import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { SharedDialogComponent } from 'src/app/modules/common-components/shared-dialog/shared-dialog.component';
import { UtilitiesService } from 'src/app/services/utilities.service';
import { WaitErrorDialogsService } from 'src/app/services/wait-error-dialogs.service';
import { tagFieldRequiredOptions, tagFieldSuffixes, tagFieldTypeOptions } from '../sms-settings.model';
import { SMS_OPTIONS } from 'src/app/constants';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-custom-tags-settings',
  templateUrl: './custom-tags-settings.component.html',
  styleUrls: ['./custom-tags-settings.component.scss']
})
export class CustomTagsSettingsComponent implements OnInit {
  @Input() customTagsConfig: any;
  @Output() onSave = new EventEmitter();
  @Output() onStaffingRoleChange = new EventEmitter<string>();
  public requiredOptions = [tagFieldRequiredOptions.Mandatory, tagFieldRequiredOptions.Optional];
  public inputTypeOptions = [tagFieldTypeOptions.MultiSelect, tagFieldTypeOptions.Select];
  public isStaffingRoleEnabled: string;
  canEdit: string
  fieldsForCustomTags: Array<any>
  preconfiguredAddtionalField: string[] = ['External Id'];
  constructor(private ds: DataService, private util: UtilitiesService, private weds: WaitErrorDialogsService, private dialog: MatDialog) { }
  customTagsAvailableFieldsFormControl = new FormControl();
  newCustomTag: string;
  fieldsForCustomTagsFormGroup = new FormGroup({});
  
  ngOnInit(): void {
    const { smsSettings } = this.ds.client;
    this.canEdit = this.customTagsConfig.canEdit;
    this.fieldsForCustomTags = this.customTagsConfig.fieldsForCustomTags;
    this.isStaffingRoleEnabled = smsSettings.isStaffingRoleEnabled || SMS_OPTIONS.NO;
    this.onStaffingRoleChange.emit(this.isStaffingRoleEnabled);

    this.customTagsAvailableFieldsFormControl.setValue(this.fieldsForCustomTags.map((field) => field.field));
    this.fieldsForCustomTags.map((field) => {
      this.fieldsForCustomTagsFormGroup.addControl(
        field.dataKey + tagFieldSuffixes.additionalFields,
        new FormControl({ value: field['selectedAdditionalFields'] || [], disabled: !this.canEdit }, [])
      );
      this.fieldsForCustomTagsFormGroup.addControl(
        field.dataKey + tagFieldSuffixes.isMandatory,
        new FormControl({ value: field['isMandatory'] ?  tagFieldRequiredOptions.Mandatory : tagFieldRequiredOptions.Optional, disabled: !this.canEdit }, [])
      );
      this.fieldsForCustomTagsFormGroup.addControl(
        field.dataKey + tagFieldSuffixes.isMultiSelect,
        new FormControl({ value: field['isMultiSelect'] ? tagFieldTypeOptions.MultiSelect : tagFieldTypeOptions.Select, disabled: !this.canEdit }, [])
      );
    });
    if(!this.canEdit) this.customTagsAvailableFieldsFormControl.disable();
  }

  saveCustomTags() {
    let dialogRef = this.dialog.open(SharedDialogComponent, { panelClass: 'shared-dialog', disableClose: false });
    dialogRef.componentInstance.data = { type: 'generic', title: 'Confirm save', message: 'This is a dangerous setting, are you absolutely sure you want to save these settings?', btnTitle: 'Yes', btn2Title: 'Cancel' };
    const onEvent = dialogRef.componentInstance.onEvent.subscribe((data: any) => {
      dialogRef.close();
      if (data.event == 'BTN_CLICK') {
        this.fieldsForCustomTags.forEach((customTag) => {
          customTag['selectedAdditionalFields'] = this.fieldsForCustomTagsFormGroup.value[customTag.dataKey + tagFieldSuffixes.additionalFields];
          customTag['isMandatory'] = this.fieldsForCustomTagsFormGroup.value[customTag.dataKey + tagFieldSuffixes.isMandatory] === tagFieldRequiredOptions.Mandatory;;
          customTag['isMultiSelect'] = this.fieldsForCustomTagsFormGroup.value[customTag.dataKey + tagFieldSuffixes.isMultiSelect] === tagFieldTypeOptions.MultiSelect;
        })
        this.onSave.emit({ type: 'customTags', isStaffingRoleEnabled: this.isStaffingRoleEnabled, fieldsForCustomTags: this.fieldsForCustomTags });
      }
    });
    dialogRef.afterClosed().subscribe(() => {
      onEvent.unsubscribe();
    });
  }

  addCustomTag() {
    const newTagValue = this.newCustomTag.trim();
    if (newTagValue) {
      if(!(/^[a-z0-9\s]+$/i).test(newTagValue)) {
        this.weds.showDialog({
          type: 'error', code: -4, title: 'Only alphanumeric characters allowed',
          message: `The ${this.newCustomTag} should have atleast 1 alphanumeric character.` });
          return;
      }
      const newTagDataKey = this.util.toAlphaNumeric(newTagValue);
      const checkDatakey = this.fieldsForCustomTags.some(customTag => customTag.dataKey == newTagDataKey);
      if (checkDatakey) {
        this.weds.showDialog({ 
          type: 'error', code: -4, title: 'Duplicate not allowed',
          message: `A ${this.newCustomTag} with a same name already exists.` });
          return;
      }
      this.fieldsForCustomTags.push({
        additionalFields: [],
        dataKey: newTagDataKey,
        dataType: 'Text',
        field: newTagValue,
      });
      this.fieldsForCustomTagsFormGroup.addControl(
        newTagDataKey + tagFieldSuffixes.additionalFields,
        new FormControl({ value: [], disabled: !this.canEdit }, [])
      );
      this.fieldsForCustomTagsFormGroup.addControl(
        newTagDataKey + tagFieldSuffixes.isMandatory,
        new FormControl({ value: tagFieldRequiredOptions.Optional, disabled: !this.canEdit }, [])
      );
      this.fieldsForCustomTagsFormGroup.addControl(
        newTagDataKey + tagFieldSuffixes.isMultiSelect,
        new FormControl({ value: tagFieldTypeOptions.MultiSelect, disabled: !this.canEdit }, [])
      );
      this.newCustomTag = '';
    }
  }

  removeFieldCustomTags(fieldToRemove) {
    this.fieldsForCustomTags = this.fieldsForCustomTags.filter(field => field.dataKey != fieldToRemove);
    this.fieldsForCustomTagsFormGroup.removeControl(fieldToRemove);
  }

  public onStaffingRoleSelectionChange(value: string) {
    this.isStaffingRoleEnabled = value;
    this.onStaffingRoleChange.emit(this.isStaffingRoleEnabled);
    if (value === SMS_OPTIONS.YES) {
      const staffingRoleField = { field: 'Staffing Role', dataKey: 'staffingRole', addtionalFields: [], disabled: true };
      
      this.fieldsForCustomTags.push(staffingRoleField);
      this.fieldsForCustomTagsFormGroup.addControl(
        'staffingRole' + tagFieldSuffixes.additionalFields,
        new FormControl({ value: [], disabled: true }, [])
      );
      this.fieldsForCustomTagsFormGroup.addControl(
        'staffingRole' + tagFieldSuffixes.isMandatory,
        new FormControl({ value: tagFieldRequiredOptions.Optional, disabled: true }, [])
      );
      this.fieldsForCustomTagsFormGroup.addControl(
        'staffingRole' + tagFieldSuffixes.isMultiSelect,
        new FormControl({ value: tagFieldTypeOptions.MultiSelect, disabled: true }, [])
      );
    } else {
      this.removeFieldCustomTags('staffingRole');
    }
  }

}
