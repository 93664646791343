import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormArray, FormGroup, Validators } from '@angular/forms';
import { SMS_OPTIONS } from 'src/app/constants';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-skill-updated-source',
  templateUrl: './skill-updated-source.component.html',
  styleUrls: ['./skill-updated-source.component.scss']
})
export class SkillUpdatedSourceComponent implements OnInit {

  @Output() onSave = new EventEmitter();
  public displayedColumns: string[] = ['sourceLabel', 'alias'];
  public skillUpdatedSource: FormGroup;
  private readonly defaultSourceArray = [
    { sourceLabel: 'lmsCourse', label: 'LMS Course', default: 'Training' },
    { sourceLabel: 'lmsCourseUpload', label: 'LMS Course Upload', default: 'Training' },
    { sourceLabel: 'spleCompletion', label: 'SPLE Completion', default: 'Training' },
    { sourceLabel: 'projectInbound', label: 'Project Inbound', default: 'Project' },
    { sourceLabel: 'projectUpload', label: 'Project Upload', default: 'Project' },
    { sourceLabel: 'userSkillInbound', label: 'User Skill Inbound', default: 'Client System' },
    { sourceLabel: 'userSkillUpload', label: 'User Skill Upload', default: 'Skill Recommendation' },
    { sourceLabel: 'resumeUpload', label: 'Resume Upload', default: 'Self Acquired' },
    { sourceLabel: 'linkedinProfileUpload', label: 'LinkedIn Profile Upload', default: 'Self Acquired' },
    { sourceLabel: 'github', label: 'GitHub', default: 'Self Acquired' },
    { sourceLabel: 'mySkills', label: 'My Skills', default: 'Self Acquired' },
    { sourceLabel: 'project', label: 'Project', default: 'Self Acquired' },
    { sourceLabel: 'skillRecommendations', label: 'Skill Recommendations', default: 'Skill Recommendation' },
    { sourceLabel: 'endorsementApproval', label: 'Endorsement Approval', default: 'Endorsed' },
    { sourceLabel: 'endorsementRejection', label: 'Endorsement Rejection', default: 'Rejected' },
    { sourceLabel: 'endorsementSkip', label: 'Endorsement Skip', default: 'Skipped' },
    { sourceLabel: 'resumeBuilder', label: 'Resume Builder', default: 'Self Acquired' },
    { sourceLabel: 'autoPopulatedSkillsBasedOnRoleCompetency', label: 'Auto-Populated Skills (Role Competency)', default: 'Self Acquired' },
    { sourceLabel: 'autoPopulatedSkillsBasedOnRoleSpecialisation', label: 'Auto-Populated Skills (Role Specialisation)', default: 'Self Acquired' },
  ];

  constructor(
    private fb: FormBuilder,
    private ds: DataService,
  ) {}

  ngOnInit(): void {
    this.buildForm();
    this.initializeSkillSources();
    this.setSkillSourcesAlias();
  }

  public saveData(): void {
    let smsSettings = this.ds.client?.smsSettings;
    if (!smsSettings?.skillSourcesAlias?.length) {
      const storedClient = JSON.parse(localStorage.getItem('currentClient') || '{}');
      smsSettings = storedClient?.smsSettings || smsSettings;
    }

    const skillUpdatedSourceSettingsData = {
      showSkillHistory: this.skillUpdatedSource?.value?.showSkillHistory || smsSettings?.showSkillHistory,
      skillSourcesAlias: this.skillUpdatedSource?.value?.skillSourcesAlias,
      type: 'skillHistory',
    };
    
    this.onSave.emit(skillUpdatedSourceSettingsData);
  }

  public get skillSourcesAliasControls(): FormArray {
    return this.skillUpdatedSource.get('skillSourcesAlias') as FormArray;
  }

  public get showSkillSourcesAliasTable(): boolean {
    return this.skillUpdatedSource?.get('showSkillHistory')?.value === SMS_OPTIONS.YES;
  }

  private buildForm(): void {
    this.skillUpdatedSource = this.fb.group({
      showSkillHistory: [SMS_OPTIONS.NO, Validators.required],
      skillSourcesAlias: this.fb.array([]),
    });
  }

  private initializeSkillSources(): void {
    const skillSourcesAliasArray = this.skillSourcesAliasControls;
    skillSourcesAliasArray.clear();
    this.populateSkillSources(this.defaultSourceArray, skillSourcesAliasArray);
  }

  private setSkillSourcesAlias(): void {
    let smsSettings = this.ds.client?.smsSettings;
    if (!smsSettings?.skillSourcesAlias?.length) {
      const storedClient = JSON.parse(localStorage.getItem('currentClient') || '{}');
      smsSettings = storedClient?.smsSettings || smsSettings;
    }

    const skillSourcesAliasArray = this.skillSourcesAliasControls;
    skillSourcesAliasArray.clear();

    const mergedSources = this.getMergedSources(smsSettings?.skillSourcesAlias || []);
    this.populateSkillSources(mergedSources, skillSourcesAliasArray);

    this.skillUpdatedSource.patchValue({
      showSkillHistory: smsSettings?.showSkillHistory || SMS_OPTIONS.NO,
    });
  }

  private getMergedSources(apiSkillSourcesAlias: any[]): any[] {
    return this.defaultSourceArray.map((defaultSource) => {
      const apiSource = apiSkillSourcesAlias.find((data) => data.sourceLabel === defaultSource.sourceLabel);
      return {
        sourceLabel: defaultSource.sourceLabel,
        label: defaultSource.label,
        alias: apiSource?.alias || null,
        default: defaultSource.default,
      };
    });
  }

  private populateSkillSources(sourceArray: any[], skillSourcesAliasArray: FormArray): void {
    sourceArray.forEach((source) => {
      skillSourcesAliasArray.push(
        this.fb.group({
         sourceLabel: [source.sourceLabel],
          label: [source.label],
          alias: [source.alias],
          default: [source.default],
        })
      );
    });
  }
}
