import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { take } from 'rxjs/operators';
import { DIALOG_TYPES, RESPONSE_STATUS, SMS_OPTIONS } from 'src/app/constants';
import { API_ENDPOINT } from 'src/app/constants/api-endpoint.constants';
import { DataService } from 'src/app/services/data.service';
import { UtilitiesService } from 'src/app/services/utilities.service';
import { WaitErrorDialogsService } from 'src/app/services/wait-error-dialogs.service';

@Component({
  selector: 'app-staffing-role-settings',
  templateUrl: './staffing-role-settings.component.html',
})
export class StaffingRoleSettingsComponent implements OnInit {
  @Output() onSave = new EventEmitter();
  public staffingRoleForm: FormGroup;
  public maxStaffingRoleMasterCount: number;

  constructor(
    private fb: FormBuilder,
    private ds: DataService,
    public utils: UtilitiesService,
    private weds: WaitErrorDialogsService,
  ) { }

  ngOnInit(): void {
    this.buildForm();
    this.getStaffingRoleMasterCount();
    this.setFormValue();
  }

  private getStaffingRoleMasterCount() {
    const loader = this.weds.showDialog({ type: DIALOG_TYPES.WAIT, code: -2 });
    const payload = { clientId: this.ds.currentAdminClientId, attributeType: 'staffingRole', tagType: 'Custom' };
    this.ds.searchData(API_ENDPOINT.GET_TAG_VALUES_FOR_TAG_ADMIN, payload )
    .pipe(take(1))
    .subscribe((res: any) => {
      this.weds.closeDialog(loader);
      if (res.status === RESPONSE_STATUS.SUCCESS) {
        this.maxStaffingRoleMasterCount = res?.data?.length;
      } else {
        this.weds.showDialog({ type: DIALOG_TYPES.GENERIC, code: 99 });
      }
    });
  }

  private buildForm() {
    this.staffingRoleForm = this.fb.group({
      isStaffingRoleMandatory: [SMS_OPTIONS.NO, Validators.required],
      maxStaffingRoleLimit: [1, [Validators.required, Validators.min(1)]],
    });
  }

  private setFormValue() {
    const { smsSettings } = this.ds.client;
    if (smsSettings) {
      this.staffingRoleForm.setValue({
        isStaffingRoleMandatory: smsSettings?.isStaffingRoleMandatory || SMS_OPTIONS.NO,
        maxStaffingRoleLimit: smsSettings?.maximumStaffingRoles || 1
      });
    }
  }

  public saveData() {
    if (this.staffingRoleForm.invalid) return;
    const staffingRoleSettingsData = {
      isStaffingRoleMandatory: this.staffingRoleForm?.value?.isStaffingRoleMandatory,
      maximumStaffingRoles: this.staffingRoleForm?.value?.maxStaffingRoleLimit,
      type: 'staffingRole',
    };
    this.onSave.emit(staffingRoleSettingsData);
  }

}
