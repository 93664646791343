import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Client } from 'src/app/models/client.model';
import { User } from 'src/app/models/user.model';
import { DataService } from 'src/app/services/data.service';
import { WaitErrorDialogsService } from 'src/app/services/wait-error-dialogs.service';
import { MatDialog } from '@angular/material/dialog';
import { SharedDialogComponent } from '../../common-components/shared-dialog/shared-dialog.component';
import { ValidateString } from 'src/app/services/validation.service';
import { EditableTableComponent } from '../../common-components/editable-table/editable-table.component';
import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { UtilitiesService } from 'src/app/services/utilities.service';
import { ACCESS_LEVELS, SMS_OPTIONS } from 'src/app/constants';
import { API_ENDPOINT } from 'src/app/constants/api-endpoint.constants';
import { take } from 'rxjs/operators';

export const DATE_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'DD MMM YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-sms-settings',
  templateUrl: './sms-settings.component.html',
  styleUrls: ['./sms-settings.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },

    { provide: MAT_DATE_FORMATS, useValue: DATE_FORMATS },
  ]
})
export class SmsSettingsComponent implements OnInit {

  @ViewChild('autosize') autosize: CdkTextareaAutosize;
  user: User;
  canEdit = false;
  client: Client;
  skillCategoriesColumns: any[];
  skillHierarchyColumns: any[];
  isLearningSettingsEnabled: boolean = false; 

  skillHierarchy: any[] = [];
  @ViewChild('skillCategories') skillCategories: EditableTableComponent;
  getDataTimer;
  skillRatings: any;
  syncButtonText: string = 'Sync Now';
  hrmsMastersConfig: object;
  hrmsMastersAccessLevel: string;
  customTagsConfig: object;
  customTagsAccessLevel: string;
  public isStaffingRoleEnabled: string = SMS_OPTIONS.NO;

  constructor(private ds: DataService, private route: ActivatedRoute, private router: Router, private weds: WaitErrorDialogsService, private fb: FormBuilder, private dialog: MatDialog, private util: UtilitiesService) { }

  ngOnInit(): void {
    this.user = this.ds.user;
    this.canEdit = this.user.role.globalAdmin ? this.user.role.globalAdmin.clients == 'Edit' : this.user.role.admin.smsSettings == 'Edit';

    this.route.params.subscribe(parameter => {
      if ((!parameter || !parameter.clientId) && !this.ds.currentAdminClientId) { this.router.navigate(['']); return; }
      if (parameter.clientId) this.ds.currentAdminClientId = parameter.clientId;
      else if (this.user.role.globalAdmin) { this.router.navigate([this.router.url + '/' + this.ds.currentAdminClientId]); return; }
      this.getData(this.ds.currentAdminClientId);
    });

    this.skillCategoriesColumns = [
      { title: 'Skill Category', key: 'name', type: 'text', disabled: !this.canEdit, required: true, validators: [Validators.required, ValidateString(2)], placeholder: 'E.g.: Technical skill, domain skill' },
    ]
    this.skillHierarchyColumns = [
      { title: 'Skill Hierarchy', key: 'name', type: 'text', disabled: !this.canEdit, required: true, validators: [Validators.required, ValidateString(2)], placeholder: 'E.g.: Skill group, Skill' },
    ];
  }
  ngOnDestroy(): void {
    if (this.getDataTimer) clearTimeout(this.getDataTimer);
  }

  onStaffingRoleChange(isEnabled: string): void {
    setTimeout(() => {
      this.isStaffingRoleEnabled = isEnabled;
    }, 0);
  }

  //--------------------------Get data-----------------------
  getData(clientId) {
    this.weds.showDialog({ type: 'wait', code: -2 });
    this.ds.searchData(API_ENDPOINT.GET_SMS_SETTINGS, { clientId: clientId }).pipe(take(1)).subscribe((rs: any) => {
      this.weds.closeDialog();
      if (rs.status == 'Success') {
        this.skillHierarchy = JSON.parse(JSON.stringify(rs.client.smsSettings.skillHierarchy));
        this.client = new Client().deserialize(rs.client);
        this.ds.updateAliasesForMenuItems(this.client);
        this.checkEnableLearningSettings();
        if (rs.maxSkillApprovalLevel) this.ds.maxSkillApprovalLevel = rs.maxSkillApprovalLevel;
        this.skillRatings = rs.client.smsSettings.skillRatings;
        if (!this.client.features || (this.client.features.sms && !this.client.features.sms.mySkills)) { this.router.navigate(['admin/companyInfo/' + this.client.clientId]) };
        this.hrmsMastersAccessLevel =  this.getAccessLevelConfig('hrmsMasters');
        this.hrmsMastersConfig = {
          canEdit: this.hrmsMastersAccessLevel === ACCESS_LEVELS.EDIT,
          hrmsMasterFields: this.client.hrmsMasterFields,
          hrmsFieldsChosenForMaster: this.client.smsSettings.hrmsFieldsChosenForMaster || [],
        };
        this.customTagsAccessLevel = this.getAccessLevelConfig('customTags');
        this.customTagsConfig = {
          canEdit: this.customTagsAccessLevel === ACCESS_LEVELS.EDIT,
          fieldsForCustomTags: this.client.smsSettings.fieldsForCustomTags || [],
        };
      } else {
        this.weds.showDialog({ type: 'generic', code: rs.error ? rs.error.code : 99 });
      }
    }, (err) => {
      this.getDataTimer = setTimeout(() => {
        if (!this.client) this.getData(clientId);
      }, 10000);
    });
  }

  private getAccessLevelConfig(featureKey: string) : string {
    const { globalAdmin, admin } = this.user?.role || {};
    const clientFeatureAccess = this.client?.features?.admin[featureKey];
    const userRoleAccess = admin?.[featureKey];
  
    if (globalAdmin) return ACCESS_LEVELS.EDIT;
    if (clientFeatureAccess === ACCESS_LEVELS.NO_ACCESS || userRoleAccess === ACCESS_LEVELS.NO_ACCESS) return ACCESS_LEVELS.NO_ACCESS;
    if (clientFeatureAccess === ACCESS_LEVELS.VIEW_ONLY || userRoleAccess === ACCESS_LEVELS.VIEW_ONLY) return ACCESS_LEVELS.VIEW_ONLY;
    
    return ACCESS_LEVELS.EDIT;
  }
  
  buildForms(mode) {
    let arr30 = []; for (let i = 1; i <= 30; i++)arr30.push(i + '');
    let shouldDisplayRatingsManager = true,
      skillApprovalsEnabled = false;
    this.skillRatings.map(rating => {
      if (rating.dataKey == 'managerialRating' && rating.useRating == 'No')
        shouldDisplayRatingsManager = false;
      if (rating.requiresApproval == 'Yes')
        skillApprovalsEnabled = true;
    });
  }

  onSkillCategoriesSave(data) {
    if (!data.length) { this.weds.showDialog({ type: 'generic', code: 133 }); return; }
    let d = { type: 'skillCategories', skillCategories: data };
    let dialogRef = this.dialog.open(SharedDialogComponent, { panelClass: 'shared-dialog', disableClose: false });
    dialogRef.componentInstance.data = { type: 'generic', title: 'Confirm Save', message: 'This is a dangerous setting, are you absolutely sure you want to save these settings?', btnTitle: 'Yes', btn2Title: 'Cancel' };
    const onEvent = dialogRef.componentInstance.onEvent.subscribe((data: any) => {
      dialogRef.close();
      if (data.event == 'BTN_CLICK') {
        this.weds.showDialog({ type: 'wait', title: 'Deleting', message: "Please wait while your data is deleted" });
        this.saveData(d);
      }
    });
    dialogRef.afterClosed().subscribe(() => {
      onEvent.unsubscribe();
    });
  }
  onSkillHierarchySave(data) {
    if (!data.length) { this.weds.showDialog({ type: 'generic', code: 134 }); return; }
    if (this.skillHierarchy[0] && this.skillHierarchy[0].hierarchyId != data[0].hierarchyId) { this.weds.showDialog({ type: 'generic', title: 'Error', message: 'You cannot delete or move the lowest level of the hierarchy', btnTitle: 'OK' }); return; }
    let d = { type: 'skillHierarchy', skillHierarchy: data };
    let dialogRef = this.dialog.open(SharedDialogComponent, { panelClass: 'shared-dialog', disableClose: false });
    dialogRef.componentInstance.data = { type: 'generic', title: 'Confirm Save', message: 'This is a dangerous setting, are you absolutely sure you want to save these settings?', btnTitle: 'Yes', btn2Title: 'Cancel' };
    const onEvent = dialogRef.componentInstance.onEvent.subscribe((data: any) => {
      dialogRef.close();
      if (data.event == 'BTN_CLICK') {
        this.weds.showDialog({ type: 'wait', title: 'Deleting', message: "Please wait while your data is deleted" });
        this.saveData(d);
      }
    });
    dialogRef.afterClosed().subscribe(() => {
      onEvent.unsubscribe();
    });
  }

  saveData(data) {
    data.clientId = this.client.clientId;
    if(data.type === 'syncEndorsementLevelChanges'){this.syncButtonText = 'Syncing...'}
    else this.weds.showDialog({ type: 'wait', code: -5 });
    this.ds.saveData('admin/saveSMSSettings', data).pipe(take(1)).subscribe((rs: any) => {
      if (data.type !== 'syncEndorsementLevelChanges') this.weds.closeDialog();

      if (rs.status == 'Success') {
        if (data.type !== 'syncEndorsementLevelChanges') setTimeout(() => { this.weds.showDialog({ type: 'generic', code: -1 }); }, 200);
        this.syncButtonText = 'Sync Now';
        if (rs.skillHierarchy) {
          this.client.smsSettings.skillHierarchy = rs.skillHierarchy;
          this.skillHierarchy = JSON.parse(JSON.stringify(rs.skillHierarchy));
        }
        if (rs.skillCategories) this.client.smsSettings.skillCategories = rs.skillCategories;
        if (rs.client) {
          this.client = new Client().deserialize(rs.client);
          this.ds.updateAliasesForMenuItems(this.client);
        }
        if (this.ds.client.clientId == this.client.clientId) this.ds.saveClient(this.client)
      } else {
        if (rs.error.code == 166) {
          this.weds.showDialog({ type: 'generic', title: 'ERROR', message: 'There are ' + rs.cnt + ' skill' + (rs.cnt == 1 ? '' : 's') + ' tagged to this category. Please update them before deleting the category', 'btnTitle': 'OK' });
          this.skillCategories.dataSource = this.client.smsSettings.skillCategories = this.client.smsSettings.skillCategories;
        } else if (rs.error.code == 346){
          this.syncButtonText = 'Sync Now'
          this.weds.showDialog({ type: 'generic', title: 'ERROR', message: rs.error ? rs.error.description : 99 });
        } else this.weds.showDialog({ type: 'generic', code: rs.error ? rs.error.code : 99 });
      }
    });
  }

  checkEnableLearningSettings() {
    const clientFeatureAccess = this.client?.features?.admin;
    const userFeatureAccess = this.user?.role?.admin;
    const isGlobalAdmin = this.user?.role?.globalAdmin;
  
    const clientHasAccess =
      clientFeatureAccess &&
      (clientFeatureAccess?.learningElementMaster === ACCESS_LEVELS.EDIT ||
        clientFeatureAccess?.learningSourceMaster === ACCESS_LEVELS.EDIT ||
        clientFeatureAccess?.learningPathMaster === ACCESS_LEVELS.EDIT);
    const adminRoleHasAccess =
      userFeatureAccess &&
      (userFeatureAccess?.learningElementMaster === ACCESS_LEVELS.EDIT ||
        userFeatureAccess?.learningSourceMaster === ACCESS_LEVELS.EDIT ||
        userFeatureAccess?.learningPathMaster === ACCESS_LEVELS.EDIT);

    this.isLearningSettingsEnabled = isGlobalAdmin ? clientHasAccess : (clientHasAccess && adminRoleHasAccess);
  }
}
